import React from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import GravityFormForm from 'gatsby-gravityforms-component';
import { AllGravityData } from '../hooks/gravity-forms';
import Link from './Link';

import FacebookIcon from '../images/facebook-icon.svg';
import TwitterIcon from '../images/twitter-icon.svg';
import LinkedInIcon from '../images/linkedin-icon.svg';
import InstagramIcon from '../images/instagram-icon.svg';
import TikTokIcon from '../images/tiktok-icon.svg';
import YouTubeIcon from '../images/youtube-icon.svg';
import XQStencil from '../images/xq-stencil.svg';
import { Fragment } from 'react';

const FooterStyles = styled.div`
  .bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .flex {
    display: flex;
  }
  .text-dark-grey {
    --text-opacity: 1;
    color: #262626;
    color: rgba(38, 38, 38, var(--text-opacity));
  }
  .pb-4 {
    padding-bottom: 1rem;
  }
  .flex-shrink-0 {
    flex-shrink: 0;
  }
  .container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  @media (min-width: 640px) {
    .container {
      max-width: 640px;
    }
  }
  .relative {
    position: relative;
  }
  .flex-col {
    flex-direction: column;
  }

  .py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .pb-12 {
    padding-bottom: 3rem;
  }
  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .pb-4 {
    padding-bottom: 1rem;
  }
  .font-medium {
    font-weight: 500;
  }
  .leading-tight {
    line-height: 1.25;
  }
  .tracking-tight {
    letter-spacing: -0.025em;
  }
  .pb-6 {
    padding-bottom: 1.5rem;
  }
  .border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .border {
    border-width: 1px;
  }
  .rounded-lg {
    border-radius: 0.5rem;
  }

  @media (min-width: 576px) {
    .container {
      max-width: 576px;
    }
    .sm\\:mr-4 {
      margin-right: 1rem;
    }
    .sm\\:w-12 {
      width: 3rem;
    }
    .sm\\:h-12 {
      height: 3rem;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: calc(100% - 5vw * 2);
    }
    .lg\\:w-1\\/2 {
      width: 50%;
    }
    .lg\\:w-10\\/12 {
      width: 83.333333%;
    }
    .lg\\:pb-8 {
      padding-bottom: 2rem;
    }
    .lg\\:text-xl {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
    .lg\\:pb-6 {
      padding-bottom: 1.5rem;
    }
    .lg\\:w-3\\/5 {
      width: 60%;
    }
    .lg\\:w-1\\/2 {
      width: 50%;
    }
    .lg\\:order-last {
      order: 9999;
    }
    .lg\\:py-12 {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
    .lg\\:flex-row {
      flex-direction: row;
    }
    .lg\\:text-3xl {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
    .lg\\:leading-110perc {
      line-height: 1.1rem;
    }
    .lg\\:text-lg {
      font-size: 18px;
    }
    .lg\\:text-90perc {
      font-size: 0.9rem;
    }
  }

  .px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .font-bold {
    font-weight: 700;
  }
  .hover\\:opacity-75:hover {
    opacity: 0.75;
  }
  .font-normal {
    font-weight: 400;
  }
  .absolute {
    position: absolute;
  }
  .bottom-0 {
    bottom: 0px;
  }
  .text-78perc {
    font-size: 0.7875rem;
  }
  .h-full {
    height: 100%;
  }
  .h-8 {
    height: 2rem;
  }
  .mb-4 {
    margin-bottom: 1rem;
  }
  .items-center {
    align-items: center;
  }
  .w-8 {
    width: 2rem;
  }
  .align-top {
    vertical-align: top;
  }
  .mr-3 {
    margin-right: 0.75rem;
  }
  .inline-block {
    display: inline-block;
  }
  .pb-8 {
    padding-bottom: 2rem;
  }
  .block {
    display: block;
  }
  .w-15 {
    width: 3.75rem;
  }
  .w-full {
    width: 100%;
  }
  .text-green {
    --text-opacity: 1;
    color: #38b44b;
    color: rgba(56, 180, 75, var(--text-opacity));
  }
  .group:hover .group-hover\\:text-dark-grey {
    --text-opacity: 1;
    color: #262626;
    color: rgba(38, 38, 38, var(--text-opacity));
  }
  .pb-60perc {
    padding-bottom: 0.6rem;
  }
  .leading-tighter {
    line-height: 1.125;
  }

  .gravityform {
    .gform_confirmation_message {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .gravityform__field__input {
      position: relative;
      --tw-bg-opacity: 1;
      background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
      padding: 1rem;
      margin-bottom: 1rem;
      width: 100%;
      border-width: 1px;
      border-style: solid;
      --tw-border-opacity: 1;
      border-color: rgba(184, 184, 184, var(--tw-border-opacity));
      font-size: 14px;
      font-family: Graphik, Helvetica, Arial, sans-serif;
      &::-moz-placeholder {
        --tw-text-opacity: 1;
        color: rgba(184, 184, 184, var(--tw-text-opacity));
        font-weight: 500;
        opacity: 0.9;
      }
      &:-ms-input-placeholder {
        --tw-text-opacity: 1;
        color: rgba(184, 184, 184, var(--tw-text-opacity));
        font-weight: 500;
        opacity: 0.9;
      }
      &::placeholder {
        --tw-text-opacity: 1;
        color: rgba(184, 184, 184, var(--tw-text-opacity));
        font-weight: 500;
        opacity: 0.9;
      }
      &[type='text'],
      &[type='email'],
      &[type='textarea'] {
        z-index: 1;
      }
    }
    label {
      position: absolute;
      z-index: 0;
      width: 0px;
      height: 0px;
      font-size: 0;
    }
    button {
      --tw-bg-opacity: 1;
      background-color: rgba(56, 180, 75, var(--tw-bg-opacity));
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      margin-top: 1rem;
      --tw-text-opacity: 1;
      color: rgba(255, 255, 255, var(--tw-text-opacity));
      font-weight: 500;
      border-radius: 0.125rem;
      &:hover {
        --tw-bg-opacity: 1;
        background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
      }
    }
    .gravityform__field {
      position: relative;
    }
    .gravityform__error_inform,
    .gravityform__error_message,
    .validation_message {
      position: relative;
    }
    .gravityform__error_inform,
    .gravityform__error_message,
    .validation_message {
      bottom: 0.5rem;
    }
    .gravityform__error_inform,
    .gravityform__error_message,
    .validation_message {
      font-size: 14px;
    }
    .gravityform__error_inform,
    .gravityform__error_message,
    .validation_message {
      --tw-text-opacity: 1;
      color: rgba(220, 38, 38, var(--tw-text-opacity));
    }
  }

  .gravityform {
    position: relative;
    display: flex;
    height: 3.75rem;
    --tw-bg-opacity: 1;
    background-color: rgba(239, 239, 239, var(--tw-bg-opacity));
    label {
      font-size: 0;
      width: 0px;
      height: 0px;
    }
    .gfield {
      display: flex;
      height: 100%;
    }
    .gform_body {
      width: 90%;
    }
    .gform_fields {
      height: 100%;
    }
    .ginput_container {
      flex-grow: 1;
    }
    .gravityform__error_inform,
    .gravityform__error_message,
    .validation_message {
      position: absolute;
    }
    .gravityform__error_inform,
    .gravityform__error_message,
    .validation_message {
      top: 4rem;
    }
    .gravityform__field__input {
      background-color: transparent;
      width: 83.333333%;
      height: 100%;
      flex-grow: 1;
      font-size: 18px;
      padding-left: 1rem;
      padding-right: 1rem;
      border-width: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      @media (min-width: 768px) {
        width: 83.333333%;

        padding-left: 20px;

        padding-right: 20px;
      }
      &::-moz-placeholder {
        --tw-text-opacity: 1;
        color: rgba(132, 132, 132, var(--tw-text-opacity));
      }
      &:-ms-input-placeholder {
        --tw-text-opacity: 1;
        color: rgba(132, 132, 132, var(--tw-text-opacity));
      }
      &::placeholder {
        --tw-text-opacity: 1;
        color: rgba(132, 132, 132, var(--tw-text-opacity));
      }
    }
    button {
      border: 0;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      font-weight: bold;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
`;

const SiteFooter = () => {
  const {
    wpgraphql: {
      xqOrgOptions: {
        xqOrgOptions: {
          socialLinks: {
            facebookUrl,
            twitterUrl,
            linkedinUrl,
            instagramUrl,
            tiktokUrl,
            youtubeUrl,
          },
          footer: {
            bottomLinks,
            leftColumn: {
              pressInfoEmail,
              pressInfoText,
              supportEmail,
              underLogoHeadline,
              underLogoText,
              careers,
            },
            formId,
          },
        },
      },
    },
  } = useStaticQuery(graphql`
    query {
      wpgraphql {
        # XQ Options
        xqOrgOptions {
          xqOrgOptions {
            footer {
              bottomLinks {
                link {
                  title
                  url
                }
              }
              leftColumn {
                pressInfoEmail
                pressInfoText
                supportEmail
                underLogoHeadline
                underLogoText
                careers {
                  title
                  url
                  target
                }
              }
              formId
            }
            socialLinks {
              facebookUrl
              instagramUrl
              linkedinUrl
              tiktokUrl
              twitterUrl
              youtubeUrl
            }
          }
        }
      }
    }
  `);

  return (
    <FooterStyles>
      <footer className="bg-white text-dark-grey pb-4 flex-shrink-0">
        <div className="container">
          <div className="relative">
            <div className="flex flex-col lg:flex-row py-10 lg:py-12 -mx-4">
              {formId && (
                <div className="lg:w-1/2 lg:order-last px-4 pb-12 lg:pb-6">
                  <div className="lg:w-3/5 text-xl lg:text-3xl pb-4 lg:pb-6 font-medium leading-tight tracking-tight">
                    Be the Change You Want to See in Education
                  </div>
                  <div className="pb-6 lg:pb-8 lg:text-xl tracking-tight leading-tight">
                    Get tips, tactics, and text that’ll lay the groundwork.
                  </div>
                  <div className="lg:w-10/12 border-white border rounded-lg">
                    <GravityFormForm
                      id={formId}
                      formData={AllGravityData()}
                      lambda={process.env.GATSBY_LAMBDA_ENDPOINT}
                    />
                  </div>
                </div>
              )}
              <div className="lg:w-1/2 px-4 pb-6">
                <Link to="/" className="pb-4 block w-15 group">
                  <XQStencil className="w-full text-green group-hover:text-dark-grey" />
                </Link>
                <ul>
                  {underLogoHeadline && (
                    <li className="pb-60perc leading-tighter lg:leading-110perc block lg:text-lg">
                      <div className="font-bold">{underLogoHeadline}</div>
                    </li>
                  )}
                  {underLogoText && (
                    <li className="pb-8 leading-tighter lg:leading-110perc block lg:text-lg">
                      {underLogoText}
                    </li>
                  )}
                  <li className="pb-6">
                    <ul>
                      {facebookUrl && (
                        <li className="inline-block align-top mr-3 sm:mr-4 mb-4">
                          <a
                            href={facebookUrl}
                            className="flex items-center w-8 sm:w-12 h-8 sm:h-12 group"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Facebook Link"
                          >
                            <FacebookIcon className="w-full h-full text-green group-hover:text-dark-grey" />
                          </a>
                        </li>
                      )}
                      {twitterUrl && (
                        <li className="inline-block align-top mr-3 sm:mr-4">
                          <a
                            href={twitterUrl}
                            className="flex items-center w-8 sm:w-12 h-8 sm:h-12 group"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Twitter Link"
                          >
                            <TwitterIcon className="w-full h-full text-green group-hover:text-dark-grey" />
                          </a>
                        </li>
                      )}
                      {linkedinUrl && (
                        <li className="inline-block align-top mr-3 sm:mr-4">
                          <a
                            href={linkedinUrl}
                            className="flex items-center w-8 sm:w-12 h-8 sm:h-12 group"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="LinkedIn Link"
                          >
                            <LinkedInIcon className="w-full h-full text-green group-hover:text-dark-grey" />
                          </a>
                        </li>
                      )}
                      {instagramUrl && (
                        <li className="inline-block align-top mr-3 sm:mr-4">
                          <a
                            href={instagramUrl}
                            className="flex items-center w-8 sm:w-12 h-8 sm:h-12 group"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Instagram Link"
                          >
                            <InstagramIcon className="w-full h-full text-green group-hover:text-dark-grey" />
                          </a>
                        </li>
                      )}
                      {tiktokUrl && (
                        <li className="inline-block align-top mr-3 sm:mr-4">
                          <a
                            href={tiktokUrl}
                            className="flex items-center w-8 sm:w-12 h-8 sm:h-12 group"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="TikTok Link"
                          >
                            <TikTokIcon className="w-full h-full text-green group-hover:text-dark-grey" />
                          </a>
                        </li>
                      )}
                      {youtubeUrl && (
                        <li className="inline-block align-top mr-3 sm:mr-4">
                          <a
                            href={youtubeUrl}
                            className="flex items-center w-8 sm:w-12 h-8 sm:h-12 group"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="YouTube Link"
                          >
                            <YouTubeIcon className="w-full h-full text-green group-hover:text-dark-grey" />
                          </a>
                        </li>
                      )}
                    </ul>
                  </li>
                  {supportEmail && (
                    <li className="pb-8 leading-tighter lg:leading-110perc block lg:text-lg">
                      <a
                        className="font-bold hover:opacity-75"
                        href="mailto:support@xqsuperschool.org"
                      >
                        {supportEmail}
                      </a>
                    </li>
                  )}
                  {careers && careers.title && (
                    <li className="pb-8 leading-tighter lg:leading-110perc block lg:text-lg">
                      <a
                        className="font-bold hover:opacity-75"
                        href={careers.url}
                        target={careers.target}
                      >
                        {careers.title}
                      </a>
                    </li>
                  )}
                  {(pressInfoEmail || pressInfoText) && (
                    <li className="pb-60perc leading-tighter lg:leading-110perc block lg:text-lg">
                      <a
                        className="block font-bold hover:opacity-75"
                        href={`mailto:${pressInfoEmail}`}
                      >
                        {pressInfoText && (
                          <div className="pb-60perc">{pressInfoText}</div>
                        )}
                        {pressInfoEmail && (
                          <div className="font-normal">{pressInfoEmail}</div>
                        )}
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="absolute bottom-0 text-78perc lg:text-90perc">
              {bottomLinks.map(({ link }, i) => {
                return (
                  <Fragment key={i}>
                    <Link to={`${process.env.GATSBY_FE_URL}${link.url.replace(process.env.GATSBY_BE_URL, '')}`} target="_self" rel="" className="hover:opacity-75">
                      {link.title}
                    </Link>
                    {i !== bottomLinks.length - 1 && (
                      <span className="px-3">|</span>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </footer>
    </FooterStyles>
  );
};

export default SiteFooter;
