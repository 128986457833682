import React, { useEffect, useState, useMemo, useContext } from 'react';
import styled from '@emotion/styled';
import Link from 'src/components/Link';
import BackArrowSvg from 'src/svg/icon-arrow.svg';
import {
  colors,
  helpers,
  typography,
  animations,
  mediaQueries as mq,
  globals,
} from 'src/styles';
import ReactHtmlParser from 'react-html-parser';
import { wpUrlToGatsbyPath } from 'src/utils/helpers';
import { withPrefix } from 'gatsby';
import { LanguageName, MobileIndicator } from './Header'
import PageContext from 'src/context/PageContext';
import { removePrefix } from 'src/utils/helpers';

const LanguageSelector = styled.div`
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  margin-left: -10px;
  width: 85px;
`

const numberWidth = '.4rem';

const removeLang = pathname => pathname.replace('/es/', '/')

const SidebarNavigation = (props) => {
  const {
    basePath,
    className,
    hideNavigation,
    location,
    pageColors,
    sidebarVisible,
    includeHeaderLinks,
  } = props;
  const { data, lang } = useContext(PageContext);
  const secondaryLangUri = data && data.secondaryLang ? data.secondaryLang.uri.replace('/design_page', '') : (lang === 'en' ? '/es/' : '/');

  const navItems = useMemo(() => props.navItems.filter(item =>
    (lang === 'en' && (!item.node.language || item.node.language.slug === lang)) || (lang === 'es' && item.node.language && item.node.language.slug === lang))
  , [props, lang])

  const [parsedItems, setParsedItems] = useState([]);

  const isCurrentParent = (item, currentPath) =>
    currentPath.indexOf(withPrefix(wpUrlToGatsbyPath(item.node.path))) === 0 &&
    wpUrlToGatsbyPath(item.node.path).length <=
      wpUrlToGatsbyPath(currentPath).length &&
    item.node.parentId === null;

  const inCurrentHierarchy = (itemPath, parentPath) =>
    itemPath.indexOf(parentPath) === 0 && itemPath.length > parentPath.length;

  useEffect(() => {
    const filterNavItems = (navItems, titleItem) =>
      navItems.filter((item) => {
        return (
          inCurrentHierarchy(item.node.path, titleItem.node.path) &&
          item.node.parentId === titleItem.node.id
        );
      });

    let activePassed = false;
    let parents = navItems
      .filter((item) => !item.node.parentId)
      .map((item) => {
        if (isCurrentParent(item, basePath)) {
          item.children = filterNavItems(navItems, item).map((child) => {
            child.children = false;
            if (
              location.href.indexOf(`/${item.node.slug}/${child.node.slug}/`) === -1 &&
              !activePassed
            ) {
              child.state = 'viewed';
            } else if (
              location.href.indexOf(`/${item.node.slug}/${child.node.slug}/`) !== -1
            ) {
              if (
                removeLang(location.pathname) === withPrefix(`/${item.node.slug}/${child.node.slug}/`)
              ) {
                child.state = 'active';
                activePassed = true;
              } else {
                child.state = 'viewed';
              }

              child.children = filterNavItems(navItems, child).map(
                (secondChild) => {
                  if (
                    location.href.indexOf(
                      `/${item.node.slug}/${child.node.slug}/${secondChild.node.slug}`
                    ) === -1 &&
                    !activePassed
                  ) {
                    secondChild.state = 'viewed';
                  } else if (
                    location.href.indexOf(
                      `/${item.node.slug}/${child.node.slug}/${secondChild.node.slug}`
                    ) !== -1
                  ) {
                    secondChild.state = 'active';
                    child.state += ' active-parent';
                    activePassed = true;
                  } else {
                    secondChild.state = '';
                  }
                  return secondChild;
                }
              );
            } else {
              child.state = '';
            }
            return child;
          });
        } else {
          item.children = false;
        }
        item.url = navItems
          .filter(({ node }) => node.parentId)
          .find(({ node }) => item.node.id === node.parentId)
          ?.node.path.replace('/design_page', '');
        return item;
      });
    if (includeHeaderLinks) {
      parents = [
        ...parents,
        ...includeHeaderLinks.map((el) => {
          el.node = {};
          el.node.title = el.linkText;
          return el;
        }),
      ];
    }
    setParsedItems(parents);
  }, [basePath, navItems, includeHeaderLinks, location]);

  return (
    <StyledDiv
      className={className}
      sidebarVisible={sidebarVisible}
      pageColors={pageColors}
    >
      <BackArrowSvg onClick={hideNavigation} className="back-arrow" />
      {parsedItems &&
        parsedItems.map((titleItem, index) => (
          <div
            key={index}
            onClick={hideNavigation}
            role="button"
            tabIndex="0"
            onKeyDown={hideNavigation}
          >
            <Link className="sidebar-title" to={titleItem.url}>
              {ReactHtmlParser(titleItem.node.title)}
            </Link>
            {titleItem.children &&
              titleItem.children.map((item, index) => (
                <div
                  className="sidebar-item"
                  key={'sidebarItem-' + index}
                  onClick={hideNavigation}
                  role="button"
                  tabIndex="0"
                  onKeyDown={hideNavigation}
                >
                  <Link
                    className={`sidebar-item-title ${item.state} ${
                      !item.children.length && item.state === 'active'
                        ? 'no-children'
                        : ''
                    }`}
                    to={wpUrlToGatsbyPath(item.node.path)}
                  >
                    <div className={`item-dot ${item.state}`} />
                    <div className="item-title">
                      <div className="item-title-inner-wrapper">
                        {ReactHtmlParser(item.node.title)}
                      </div>
                    </div>
                  </Link>
                  {item.children &&
                    item.children.map((section, index) => (
                      <Link
                        className={`section-item-title ${section.state}`}
                        key={'sidebar-l2-' + index}
                        to={wpUrlToGatsbyPath(section.node.path)}
                      >
                        <div className={`item-dot ${section.state}`} />
                        {`${ReactHtmlParser(
                          section.node.title.replace(/[^\d]*(\d)/, '$1.')
                        )}`}
                        {section.node.designPagesModules.sidebarDescription && (
                          <span>
                            {' '}
                            {section.node.designPagesModules.sidebarDescription}
                          </span>
                        )}
                      </Link>
                    ))}
                </div>
              ))}
          </div>
        ))}
      <p>{lang === 'en' ? 'Language' : 'Lengua'}</p>
      <LanguageSelector>
        <Link to={lang !== 'en' ? secondaryLangUri : removePrefix(location.pathname)}>
          <LanguageName active={lang === 'en'}>English {lang === 'en' && <MobileIndicator />}</LanguageName>
        </Link>
        <Link to={lang !== 'es' ? secondaryLangUri : removePrefix(location.pathname)}>
          <LanguageName active={lang === 'es'}>Español {lang === 'es' && <MobileIndicator />}</LanguageName>
        </Link>
      </LanguageSelector>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  pointer-events: all;
  ${helpers.responsiveStyles('padding-bottom', 120, 90, 70, 40)}
  color: ${colors.textColor};

  ${mq.largeAndBelow} {
    top: 85px;
    background: ${colors.bgColor};
    height: 100%;
    left: 0;
    overflow: auto;
    padding: 20px ${globals.containerSmallMargins} 100px;
    position: fixed;
    transition: transform ${animations.slowSpeed}
      cubic-bezier(0.44, 0.24, 0.16, 1);
    z-index: 8;
    ${({ sidebarVisible }) =>
      sidebarVisible
        ? `
      transform: none;
    `
        : `
      transform: translate3d(-100%, 0, 0);
    `}
  }

  .sidebar-title {
    ${typography.h5}
    ${helpers.responsiveStyles('margin-top', 10, 8, 6, 4)}
    ${helpers.responsiveStyles('margin-bottom', 10, 8, 6, 4)}
    display: block;
    font-weight: 700;
    max-width: 13em;

    ${mq.largeAndBelow} {
      ${typography.h6}
      align-items: center;
      cursor: pointer;
      display: flex;
      font-weight: 700;
      margin: 0;
      padding: 8px 0;
    }
  }

  .item-dot {
    ${helpers.responsiveStyles('top', 14, 14, 11, 9)}
    background-color: ${colors.bgColor};
    border-radius: 50%;
    border: 1px solid ${colors.grey};
    display: block;
    height: 11px;
    left: -24px;
    margin-top: -6px;
    position: absolute;
    transition: background-color ${animations.mediumSpeed} ease-in-out;
    width: 11px;
    z-index: 1;

    &.active,
    &.viewed {
      background-color: ${({ pageColors }) =>
        pageColors ? colors[pageColors.mainColor] : colors.green};
      border-color: ${({ pageColors }) =>
        pageColors ? colors[pageColors.mainColor] : colors.green};
    }

    &.active {
      @keyframes beacon {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.2);
        }
        100% {
          transform: scale(1);
        }
      }
      animation: beacon 1.25s ease-in-out infinite;
    }
  }

  .sidebar-item-title {
    ${typography.bodyMedium}
    align-items: stretch;
    color: 'inherit';
    cursor: pointer;
    display: flex;
    display: inline-block;
    font-weight: 500;
    margin: 0.2em 0;
    position: relative;
    transition: color ${animations.mediumSpeed} ease-in-out;
    width: 100%;

    .item-dot {
      margin-top: -6px;
    }

    .item-title {
      &-inner-wrapper {
        display: inline;
        margin: 0;
        padding: 0.4em 0;

        ${mq.largeAndBelow} {
          font-weight: 500;
        }
      }
    }

    &::after {
      background: ${colors.grey};
      content: '';
      top: 18px;
      display: block;
      left: -19px;
      height: 110%;
      position: absolute;
      width: 1px;
      z-index: 0;

      ${mq.largeAndBelow} {
        top: 14px;
      }
    }

    &.viewed::after {
      background: ${({ pageColors }) =>
        pageColors ? colors[pageColors.mainColor] : colors.green};
      left: -20px;
      width: 3px;
    }

    &.active,
    &.active-parent {
      color: ${colors.bgColor};

      .item-title {
        > div {
          background-color: ${({ pageColors }) =>
            pageColors ? colors[pageColors.mainColor] : colors.green};
          box-shadow: -${numberWidth} 0 0 ${({ pageColors }) => (pageColors ? colors[pageColors.mainColor] : colors.green)},
            10px 0 0
              ${({ pageColors }) =>
                pageColors ? colors[pageColors.mainColor] : colors.green};
        }
      }
    }
  }

  .sidebar-item {
    padding-left: 24px;
    position: relative;

    &:last-of-type {
      .section-item-title:last-of-type::after,
      .sidebar-item-title:not([class*='active'])::after,
      .sidebar-item-title.no-children::after {
        background: transparent;
      }
    }
  }

  .section-item-title {
    ${typography.body}
    ${helpers.responsiveStyles('line-height', 24, 19, 18, 16)}
    color: ${colors.lightTextColor};
    cursor: pointer;
    display: block;
    font-weight: 500;
    margin: 1em 0;
    max-width: 15em;
    padding-left: ${numberWidth};
    position: relative;
    transition: color ${animations.mediumSpeed} ease-in-out;

    &:hover {
      color: ${colors.textColor};
    }

    &.active {
      color: ${({ pageColors }) =>
        pageColors ? colors[pageColors.mainColor] : colors.green};
    }

    .item-dot {
      ${helpers.responsiveStyles('top', 9, 9, 9, 9)}
    }

    &::after {
      background: ${colors.grey};
      content: '';
      top: 14px;
      display: block;
      left: -19px;
      height: calc(100% + 6px);
      position: absolute;
      width: 1px;
      z-index: 0;
    }

    &.viewed::after {
      background: ${({ pageColors }) =>
        pageColors ? colors[pageColors.mainColor] : colors.green};
      left: -20px;
      width: 3px;
    }

    &:last-of-type::after {
      height: calc(100% + 16px);
    }
  }

  .item-title {
    font-weight: bold;
    line-height: inherit;
    max-width: 12em;
  }

  .back-arrow {
    color: ${colors.textColor};
    display: none;
    height: auto;
    margin-right: 14px;
    position: absolute;
    right: 0;
    top: 17px;
    transform: rotate(-180deg);
    width: 18px;

    * {
      fill: currentColor;
    }

    ${mq.largeAndBelow} {
      display: block;
    }
  }
`;

export default SidebarNavigation;
