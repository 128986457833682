import { responsiveStyles } from './helpers'

const breakpointSpacing = [120, 90, 70, 40];

export const verticalMargins = `
  ${ responsiveStyles('margin-top', ...breakpointSpacing) }
  ${ responsiveStyles('margin-bottom', ...breakpointSpacing) }
`
export const verticalPaddingTop = `
  ${ responsiveStyles('padding-top', ...breakpointSpacing) }
`
export const verticalPaddingBottom = `
  ${ responsiveStyles('padding-bottom', ...breakpointSpacing) }
`
export const verticalPadding = `
  ${ verticalPaddingTop }
  ${ verticalPaddingBottom }
`
export const containerStyles = `
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`
export const containerLargeMargins = '5vw'
export const containerMediumMargins = '5vw'
export const containerSmallMargins = '20px'
