import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Global, css } from '@emotion/react';
import globalStyles from 'src/styles/globalStyles';
import Header from 'src/components/Header';
import { checkForHero, filterNavLang } from 'src/utils/helpers';
import IntersectionObserverPolyfill from 'src/components/IntersectionObserverPolyfill';
import PageContext from 'src/context/PageContext';
import SiteFooter from 'src/components/SiteFooter';

const Layout = ({ data, location, children, pageContext }) => {
  const {
    designPages,
    wp: { seo },
  } = useStaticQuery(graphql`
    query {
      wp {
        seo {
          schema {
            siteName
            siteUrl
          }
        }
      }
      designPages: allWpDesignPage(sort: { fields: menuOrder, order: ASC }) {
        edges {
          node {
            id
            parentId
            slug
            path: uri
            title
          }
        }
      }
    }
  `);

  const parents = designPages.edges.filter(
    ({ node }) => !node.parentId
  );
  const lang = location.pathname.replace('/inabox', '').startsWith('/es/') ? 'es' : 'en'
  const currentLangParents = parents.filter(filterNavLang(lang))

  const headerNavLinks = currentLangParents.map((parent) => {
    const currentNode = designPages.edges
      .filter(({ node }) => node.parentId)
      .find(({ node }) => parent.node.id === node.parentId);
    const url = currentNode
      ? currentNode.node.path.replace('/design_page', '')
      : '';
    return {
      linkText: parent.node.title,
      url,
    };
  });

  let hasHero = false;
  let pageColors = { mainColor: 'green' };

  if (data) {
    hasHero = checkForHero(data);
    if (data.page && data.page.designPagesModules) {
      pageColors = data.page.designPagesModules.colorTheme;
    }
    if (!('secondaryLang' in data) && 'secondaryLang' in pageContext) {
      data.secondaryLang = pageContext.secondaryLang;
    }
  }

  return (
    <PageContext.Provider
      value={{
        headerNavLinks,
        title: seo.schema.siteName,
        location,
        hasHero,
        pageColors,
        data,
        lang,
      }}
    >
      <Global
        styles={css`
          ${globalStyles}
        `}
      />
      <IntersectionObserverPolyfill className="site-container">
        <Header />
        {children}
        <SiteFooter />
      </IntersectionObserverPolyfill>
    </PageContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
};

export default Layout;
