import styled from '@emotion/styled'
import { colors, helpers, animations, mediaQueries as mq } from 'src/styles'

const Overlay = styled.div`
  display: none;
  transition: opacity ${ animations.slowSpeed } ease-in-out;
  ${ mq.largeAndBelow } {
    position: fixed;
    display: block;
    ${ helpers.responsiveStyles('top', 90, 80, 60, 50) }
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${ colors.black };
    z-index: 4;
    ${ ({ visible }) => visible ? `
      opacity: .68;
      pointer-events: all;
    ` : `
      opacity: 0;
      pointer-events: none;
    ` }
  }
`


export default Overlay