import React from 'react'
import MenuIcon from 'src/components/MenuIcon'
import styled from '@emotion/styled'
import LogoLockup from 'src/components/LogoLockup'
import { mediaQueries as mq } from 'src/styles'

const LogoCol = styled.div`
  display: flex;
  align-items: center;
`
const MenuIconWrap = styled.a`
  margin-right: 1rem;
  margin-top: -1px;
  ${ mq.largerAndUp } {
    display: none;
  }
`

export default function Hamburger ({open, onClick, title}) {
  return (
    <LogoCol>
      <MenuIconWrap onClick={onClick}>
        <MenuIcon open={open} />
      </MenuIconWrap>
      <LogoLockup title={title} open={open} />
    </LogoCol>
  )
}