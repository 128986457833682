import { useStaticQuery, graphql } from 'gatsby';

export const AllGravityData = () => {
	const { allGfForm } = useStaticQuery(
		graphql`
				query {
					allGfForm {
						edges {
							node {
								formId
								title
								description
								slug
								apiURL
								labelPlacement
								descriptionPlacement
								formFields {
									...GravityFormField
								}
								button {
									text
								}
								confirmations {
									id
									name
									isDefault
									type
									message
									url
									queryString
								}
							}
						}
					}
				}
			`
	);
	return allGfForm;
};