import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ScrollEntrance from 'src/components/ScrollEntrance';
import { mediaQueries as mq } from 'src/styles';

const StyledImage = styled(GatsbyImage)`
  ${({ size }) =>
    size === 'large' &&
    `
    display: none;

    ${mq.mediumAndUp} {
      display: block;
    }
  `}

  ${({ size }) =>
    size === 'small' &&
    `
    ${mq.mediumAndUp} {
      display: none;
    }
  `}
`;

const ResponsiveImage = ({
  image,
  className,
  animate = true,
  objectFit,
  imgStyle,
  objectPosition,
}) => {
  const renderImage = (source, i) => {
    const gImg = getImage(image[source]?.localFile || image[source]);
    return gImg && (
      <StyledImage
        className={className}
        image={gImg}
        objectFit={objectFit}
        imgStyle={imgStyle}
        objectPosition={objectPosition}
        alt={image[source]?.alt || 'placeholder'}
        size={source}
        key={i}
      />
    );
  };

  const Wrapper = animate ? ScrollEntrance : Fragment;
  return (
    <Wrapper>
      {Object.keys(image).map((source, i) => renderImage(source, i))}
    </Wrapper>
  );
};

const Image = ({ image, className, animate }) => (
  <ResponsiveImage image={image} className={className} animate={animate} />
);

export { ResponsiveImage, Image as default };
