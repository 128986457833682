import React from 'react';
import styled from '@emotion/styled';
import Link from 'src/components/Link';
import { mediaQueries as mq } from 'src/styles';
import Logo from 'src/svg/inabox-logo.svg';

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  ${mq.mediumAndBelow} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const LogoLockup = ({ className }) => (
  <LogoWrap className={className}>
    <Link to="/" style={{ lineHeight: 'initial' }}>
      <Logo />
    </Link>
  </LogoWrap>
);

export default LogoLockup;
