import React from 'react'
import styled from '@emotion/styled'

const iconSpeed = '.4s'
const iconWeight = 3
const iconHeight = 21
const iconWidth = 30

const Bar = styled.div`
  background: currentcolor;
  height: ${ iconWeight + 'px' };
  position: absolute;
  left: 0;
  right: 0;
  transition: transform ${ iconSpeed } ease-in-out, opacity ${ iconSpeed } ease-in-out, right ${ iconSpeed } ease-in-out, left ${ iconSpeed } ease-in-out;
  ${ ({ position }) => position === 'top' && `
    top: 0;
  ` }
  ${ ({ position }) => position === 'bottom' && `
    bottom: 0;
  ` }
  ${ ({ position }) => position === 'middle' && `
    top: 50%;
    transform-origin: left center;
    margin-top: -${ iconWeight / 2 }px;
  ` }
`

const Wrapper = styled.div`
  width: ${ iconWidth + 'px' };
  height: ${ iconHeight + 'px' };
  position: relative;
  ${ ({ open }) => open ? `
    ${ Bar } {
      &.top {
        transform: translate3d(0, ${ iconHeight / 2 - iconWeight / 2 + 'px' }, 0) rotate(45deg);
        right: 0;
      }
      &.bottom {
        transform: translate3d(0, -${ iconHeight / 2 - iconWeight / 2 + 'px' }, 0) rotate(-45deg);
        left: 0;
      }
      &.middle {
        transform: scaleX(0);
        opacity: 0;  
      }
    }
  ` : `
    &:hover {
      ${ Bar } {
        &.top {
          right: 6px;
        }
        &.bottom {
          left: 6px;
        }
      }
    }
  ` }
`

const MenuIcon = ({ open }) => (
  <Wrapper open={open}>
    <Bar position="top" className="top" />
    <Bar position="middle" className="middle" />
    <Bar position="bottom" className="bottom" />
  </Wrapper>
)

export default MenuIcon
