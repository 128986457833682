import { responsiveStyles } from './helpers'
import * as mq from './mediaQueries'
import './fonts'

// Place global Typography in this file
export const primaryFont = `Graphik, -apple-system, Helvetica, sans-serif`


export const bodyMedium = `
  ${ responsiveStyles('font-size', 24, 20, 18, 15) }
  line-height: 1.5;
  font-family: ${ primaryFont };
  letter-spacing: -.025em;
`
export const body = `
  ${ responsiveStyles('font-size', 20, 16, 15, 13) }
  ${ responsiveStyles('line-height', 27, 24, 23, 22) }
  font-family: ${ primaryFont };
  letter-spacing: -.025em;
`
export const bodySmall = `
  ${ responsiveStyles('font-size', 16, 14, 13, 13) }
  line-height: 1.6;
  font-family: ${ primaryFont };
  letter-spacing: -.025em;
`
export const h2 = `
  ${ responsiveStyles('font-size', 70, 60, 42, 28) }
  line-height: 1.1666;
  font-family: ${ primaryFont };
  font-weight: 600;
  letter-spacing: -.02em;
`

export const h3 = `
  ${ responsiveStyles('font-size', 60, 48, 36, 22) }
  line-height: 1.16667;
  font-family: ${ primaryFont };
  font-weight: 500;
  letter-spacing: -.02em;
`

export const h4 = `
  ${ responsiveStyles('font-size', 32, 26, 18, 18) }
  line-height: 1.25;
  font-family: ${ primaryFont };
  font-weight: 600;
  letter-spacing: -.02em;
`

export const h5 = `
  ${ responsiveStyles('font-size', 28, 26, 22, 16) }
  font-family: ${ primaryFont };
  font-weight: 500;
  line-height: 1.3333;
  letter-spacing: -.025em;
`
export const h6 = `
  ${ responsiveStyles('font-size', 18, 16, 15, 14) }
  font-family: ${ primaryFont };
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: .03em;
  text-transform: uppercase;
`
export const trackingTighter =  `-0.05em`
export const trackingTight	=  `-0.025em`
export const trackingNormal = 0;
export const trackingWide= `0.025em`
export const trackingWider=  `0.05em`

export const leadingTightest = 1.0625
export const leadingTighter = 1.16667
export const leadingTight = 1.25
export const leadingSnug = 1.333

export const fontSizeExtraSmall = `12px`
export const fontSizeSmall = `13px`
export const fontSizeMedium = `16px`
export const fontSizeLarge = `18px`
export const fontSizeExtraLarge = `22px`
export const fontSizeExtraLarge2 = `26px`
export const fontSizeExtraLarge3 = `32px`
export const fontSizeExtraLarge4 = `36px`
export const fontSizeExtraLarge5 = `48px`
export const fontSizeExtraLarge6 = `96px`

export const heading1 = `
  font-size: ${fontSizeExtraLarge4};
  line-height: ${leadingTightest};
  font-weight: 500;
  line-height: 1.0625em;
  letter-spacing: -.025em;

  ${ mq.largeAndUp } {
    font-size: ${fontSizeExtraLarge6};
  }
`
export const bodyLarge = `
  font-size: ${fontSizeExtraLarge};
  line-height: ${leadingSnug};
`