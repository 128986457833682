import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import Container from 'src/components/Container';
import GridStyles from 'src/components/GridStyles';
import Link from 'src/components/Link';
import Image from 'src/components/Image';
import { navTimeout } from 'src/components/NavigationStyles';
import { colors, typography, mediaQueries as mq, animations } from 'src/styles';
import { Transition } from 'react-transition-group';
import { gsap } from 'gsap';

const GlobalNavWrapper = styled.div`
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;
const Grid = styled(GridStyles.Grid)``;
const GridColumn = styled(GridStyles.GridColumn)`
  ${mq.largerAndUp} {
    -ms-grid-column: ${({ index }) => index * 4 + 1};
    grid-column: span 4;
  }
`;
const GridTextColumn = styled(GridColumn)`
  -ms-grid-row: 3;
  grid-row: 3;
  ${mq.largerAndUp} {
    -ms-grid-row: 1;
    grid-row: 1;
  }
`;
const CloseHitArea = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
`;
const Paragrpah = styled.p`
  font-size: ${typography.fontSizeMedium};
  font-weight: 500;
  &:first-child {
    margin-top: 0;
  }
`;
const Panel = styled.div`
  position: relative;
  z-index: 2;
  background-color: ${colors.green};
  padding-bottom: 2.5em;
  color: ${colors.white};
  padding-top: 100px;
  ${mq.largerAndUp} {
    padding-top: 6.5rem;
  }
`;
const LargeLink = styled(Link)`
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  ${mq.largerAndUp} {
    margin-bottom: 2.5em;
  }
`;
const LargeLinkText = styled.div`
  font-size: ${typography.fontSizeExtraLarge2};
  font-weight: 600;
  margin: 0;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin-top: -0.1em;
  padding-bottom: 0.1em;
  line-height: ${typography.leadingTighter};
  ${mq.largerAndUp} {
    font-size: ${typography.fontSizeExtraLarge4};
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 3px;
    background-color: currentcolor;
    width: 100%;
    bottom: 0;
    left: 0;
    transform-origin: left center;
    transition: transform ${animations.mediumSpeed} ease-in-out;
    transform: scaleX(0);

    ${LargeLink}:hover & {
      transform: none;
    }
  }
`;
const LargeLinkImageWrap = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 50%;
  display: none;
  ${mq.largerAndUp} {
    display: block;
  }
`;
const StyledLink = styled(Link)`
  display: block;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;

  ${mq.largerAndUp} {
    display: none;
  }
`;

export default function GlobalNavigation({
  isOpen,
  className,
  closeNavigation,
}) {
  const {
    wp: { designPagesOptions },
  } = useStaticQuery(graphql`{
  wp {
    designPagesOptions {
      designPagesOptions {
        globalNavigationLinks {
          image {
            alt: altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 100, placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
          url
          linkText
        }
      }
    }
  }
}
`);
  const { globalNavigationLinks } = designPagesOptions.designPagesOptions;

  const splitArray = [
    globalNavigationLinks.slice(0, (globalNavigationLinks.length + 1) / 2),
    globalNavigationLinks.slice((globalNavigationLinks.length + 1) / 2),
  ];

  const onPanelEnter = (node) => {
    gsap.fromTo(
      node,
      { duration: navTimeout / 1000, yPercent: -100 },
      { yPercent: 0, ease: 'power2.out' }
    );
    const nodelist = node.querySelectorAll('.text, .list-1, .list-2');
    const els = [].slice.call(nodelist);
    els.forEach((el) => {
      const index = parseFloat(
        el.className.indexOf('-') > -1 ? el.className.split('-')[1] : 0
      );
      gsap.fromTo(
        el,
        { duration: navTimeout / 1000, y: -40, autoAlpha: 0 },
        {
          y: 0,
          autoAlpha: 1,
          delay: navTimeout / 2 / 1000 + 0.075 * index,
          ease: 'power2.out',
        }
      );
    });
  };

  const onPanelExit = (node) => {
    gsap.fromTo(
      node,
      { duration: navTimeout / 1000, yPercent: 0 },
      { yPercent: -100, ease: 'power2.out' }
    );
  };

  const renderListItem = (item, i) => {
    return (
      <li key={i}>
        <LargeLink to={item.url}>
          <LargeLinkImageWrap data={item}>
            <Image
              animate={false}
              image={{image: item.image}}
              imgStyle={{ display: 'block' }}
            />
          </LargeLinkImageWrap>
          <LargeLinkText>{item.linkText}</LargeLinkText>
        </LargeLink>
      </li>
    );
  };

  return (
    <GlobalNavWrapper className={className}>
      <CloseHitArea onClick={closeNavigation} />
      <Transition
        in={isOpen}
        timeout={navTimeout}
        onEnter={onPanelEnter}
        onExit={onPanelExit}
        appear
      >
        <Panel className="panel">
          <Container>
            <Grid>
              <GridColumn index={0}>
                <StyledLink to="https://xqsuperschool.org">
                  xqsuperschool.org
                </StyledLink>
                <ul>{splitArray[0].map(renderListItem)}</ul>
              </GridColumn>
              <GridColumn index={1}>
                <ul>{splitArray[1].map(renderListItem)}</ul>
              </GridColumn>
              <GridTextColumn index={2}>
                <Paragrpah>
                  XQ supports educators, students, families, and other
                  civic-minded people in their efforts to reimagine high school
                  education in the United States.
                </Paragrpah>
                <Paragrpah>
                  Our mission is to fuel America’s collective creativity to
                  transform high school so every student succeeds - no matter
                  their race, gender, or ZIP code.
                </Paragrpah>
              </GridTextColumn>
            </Grid>
          </Container>
        </Panel>
      </Transition>
    </GlobalNavWrapper>
  );
}
