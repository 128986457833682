import MaterialIconsWoff from '../fonts/icons/material-icons-regular.woff'
import MaterialIconsWoff2 from '../fonts/icons/material-icons-regular.woff2'

import GraphikLightWoff from '../fonts/graphik/graphik-web-light.woff'
import GraphikLightWoff2 from '../fonts/graphik/graphik-web-light.woff2'

import GraphikRegularWoff from '../fonts/graphik/graphik-web-regular.woff'
import GraphikRegularWoff2 from '../fonts/graphik/graphik-web-regular.woff2'

import GraphikMediumWoff from '../fonts/graphik/graphik-web-medium.woff'
import GraphikMediumWoff2 from '../fonts/graphik/graphik-web-medium.woff2'

import GraphikSemiboldWoff from '../fonts/graphik/graphik-web-semibold.woff'
import GraphikSemiboldWoff2 from '../fonts/graphik/graphik-web-semibold.woff2'

import GraphikBoldWoff from '../fonts/graphik/graphik-web-bold.woff'
import GraphikBoldWoff2 from '../fonts/graphik/graphik-web-bold.woff2'

import GraphikBlackWoff from '../fonts/graphik/graphik-web-black.woff'
import GraphikBlackWoff2 from '../fonts/graphik/graphik-web-black.woff2'

import GraphikSuperWoff from '../fonts/graphik/graphik-web-super.woff'
import GraphikSuperWoff2 from '../fonts/graphik/graphik-web-super.woff2'

export const fontFace = (fontName, woff, woff2, fontWeight = 'normal') => `
  @font-face {
    font-family: '${ fontName }';
    src:  url('${ woff }') format('woff'),
          url('${ woff2 }') format('woff2');
    font-weight: ${ fontWeight };
    font-style: normal;
  }
`
export const MaterialIcons = 'Material Icons'
export const MaterialIconsFont = fontFace(MaterialIcons, MaterialIconsWoff, MaterialIconsWoff2)

export const GraphikLight = 'Graphik'
export const GraphikLightFont = fontFace(GraphikLight, GraphikLightWoff, GraphikLightWoff2, 300)

export const Graphik = 'Graphik'
export const GraphikRegularFont = fontFace(Graphik, GraphikRegularWoff, GraphikRegularWoff2)

export const GraphikMedium = 'Graphik'
export const GraphikMediumFont = fontFace(GraphikMedium, GraphikMediumWoff, GraphikMediumWoff2, 500)

export const GraphikSemibold = 'Graphik'
export const GraphikSemiboldFont = fontFace(GraphikSemibold, GraphikSemiboldWoff, GraphikSemiboldWoff2, 600)

export const GraphikBold = 'Graphik'
export const GraphikBoldFont = fontFace(GraphikBold, GraphikBoldWoff, GraphikBoldWoff2, 700)

export const GraphikBlack = 'Graphik'
export const GraphikBlackFont = fontFace(GraphikBlack, GraphikBlackWoff, GraphikBlackWoff2, 800)

export const GraphikSuper = 'Graphik'
export const GraphikSuperFont = fontFace(GraphikSuper, GraphikSuperWoff, GraphikSuperWoff2, 900)
