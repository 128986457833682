export const addNextPrevBgColorProp = (c, index, components) => {
  if (index > 0 && index < components.length - 1) {
    c.prevBgColor = components[index - 1].bgColor
    c.nextBgColor = components[index + 1].bgColor
  } else if (index === 0) {
    c.prevBgColor = null
    if (components[index + 1]) {
      c.nextBgColor = components[index + 1].bgColor
    } else {
      c.nextBgColor = null
    }
  } else if (index === components.length - 1) {
    if (components[index - 1]) {
      c.prevBgColor = components[index - 1].bgColor
    } else {
      c.prevBgColor = null
    }
    c.nextBgColor = null
  } else {
    c.prevBgColor = null
    c.nextBgColor = null
  }
}

export const addIsFirstProp = (c, index) => {
  if (index === 0) {
    c.isFirst = true
  } else {
    c.isFirst = false
  }
}


export const checkForHero = (data) => {
  const { page, resources } = data;
  if (page && page.designPagesModules) {
    return page.designPagesModules.heroType !== 'null' && !!page.designPagesModules.heroType
  } else if (resources) {
    return true
  } else {
    return false
  }
}

export const wpUrlToGatsbyPath = (url) => {
  if (!url) return '';
  return url.replace('/design_page', '')
}

export const getSectionFromUrl = url => {
  if (url.startsWith('http')) return false
  const normalizedUrl = removeLang(removePrefix(url));
  const parts = normalizedUrl.split('/');
  if (parts.length >= 2) return parts[1]
  return ''
}

export const removePrefix = url => url.replace(process.env.GATSBY_PROJECT_DIR, '');
export const removeLang = url => url.replace('/es/', '/');

export const filterNavLang = lang => item => lang === 'es' ? item.node.path.startsWith('/es/') : !item.node.path.startsWith('/es/')

export const hasResources = (mod) => (
  mod.video.length || mod.diveDeep.length || mod.moreResources.length || mod.downloadable.length
)