import Link from 'src/components/Link'
import styled from '@emotion/styled'
import { colors, helpers, typography, animations, mediaQueries as mq, globals } from 'src/styles'

export const navTimeout = 500;

export const NavLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 48px;
`
export const MobileNavLinks = styled.div`
  background: ${ colors.lightGrey };
  display: ${ ({ visible }) => visible ? 'none' : 'flex' };
  padding-left: ${ globals.containerSmallMargins };
  padding-right: ${ globals.containerSmallMargins };
  justify-content: space-between;

  ${ mq.largerAndUp } {
    display: none;
  }
`
export const NavLink = styled(Link)`
  display: block;
  padding: 11px 0 10px;
  ${ typography.h6 }
  font-weight: 700;
  ${ helpers.responsiveStyles('margin-right', 36, 24, 20, 0) }
  color: ${ colors.white };
  height: 34px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  transition: opacity ${ navTimeout }ms ease-in-out;
  ${ ({ active }) => active && `
    opacity: .5;
  `}

  ${ ({ active }) => !active && `
    &:hover {
      opacity: .6;
    }
  `} 
`
export const NavLinkMobile = styled.div`
  display: block;
  padding: 13px 0 10px;
  ${ typography.h6 }
  font-weight: 700;
  cursor: pointer;
  color: ${ colors.textColor };
  padding-top: 11px;
  height: 34px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  transition: color ${ animations.mediumSpeed } ease-in-out;

  &:hover {
    color: ${ colors.green }
  }

  ${ ({ active }) => active && `
    color: ${ colors.green };
  `}
`
