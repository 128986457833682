import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Transition } from 'react-transition-group';
import { gsap } from 'gsap';

const ScrollEntrance = ({
  children,
  className,
  transform = { y: 40 },
  once = false,
}) => {
  const [ref, inView] = useInView({ triggerOnce: once });
  const duration = 0.65;
  const delay = 0.075;

  const onEnter = (node) => {
    const nodes = [].slice.call(node.childNodes);
    nodes.forEach((el, i) => {
      gsap.fromTo(
        el,
        { duration, ...transform, autoAlpha: 0 },
        {
          x: 0,
          y: 0,
          z: 0,
          autoAlpha: 1,
          delay: (i + 1) * delay,
          ease: 'power2.out',
        }
      );
    });
  };

  return (
    <Transition in={inView} timeout={0} onEnter={onEnter}>
      <div
        ref={ref}
        className={className}
        style={{ visibility: `${inView ? 'visible' : 'hidden'}` }}
      >
        {children}
      </div>
    </Transition>
  );
};

export default ScrollEntrance;
