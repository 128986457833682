import styled from '@emotion/styled';
import { mediaQueries as mq } from 'src/styles';

const Grid = styled.div`
  display: -ms-grid;
  display: grid;
  grid-template-rows: auto;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-gap: 1.5em;
  ${mq.largeAndUp} {
    grid-gap: 2em;
  }
`;
const GridColumn = styled.div`
  -ms-grid-column-span: 12;
  grid-column: span 12;
  height: 100%;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding: 1.5em;
    ${mq.largeAndUp} {
      padding: 2em;
    }
  }
`;

const exports = {
  Grid,
  GridColumn,
};

export default exports;
