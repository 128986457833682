import { lighten, darken, rgba } from 'polished'

export const bgColor = '#fff'

export const black = '#000'
export const white = '#fff'

// Site Specific Colors
export const grey = '#B8B8B8'
export const grey2 = '#D6D6D6'
export const grey3 = '#C2C2C2'
export const darkGrey = '#262626'
export const blue = '#02BEDF'
export const green = '#3EB650'
export const orange = '#e15321'
export const purple = '#602f75'
export const yellow = '#fff406'

// Basic Colors
export const alert = orange
export const notify = '#f7c24e'
export const success = green
export const textColor = '#504F4F'
export const lightTextColor = rgba(textColor, 0.4)
export const lightGrey = '#f6f7f9'

// Color Variations
export const orangeLighten = lighten(0.45, orange)
export const orangeDarken = darken(0.07, orange)
export const blueLighten = lighten(0.52, blue)
export const blueDarken = darken(0.07, blue)
export const greenLighten = '#f2faed';
export const greenDarken = darken(0.07, green)
export const purpleLighten = lighten(0.52, purple)
export const purpleDarken = darken(0.07, purple)
