import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { colors, mediaQueries as mq } from 'src/styles'

const StyledDiv = styled.div`
  bottom: -5px;
  background-color: ${ ({ pageColors }) => pageColors ? colors[`${pageColors.mainColor}Darken`] : colors.greenDarken};
  height: 5px;
  left: 0;
  position: absolute;
  display: ${ ({ visible }) => visible ? 'none' : 'block' };
  width: ${ ({ scrollPerc }) => scrollPerc ? scrollPerc : 0 }%;
  ${ mq.largerAndUp } {
    display: none;
  }
`

export default function Progress({pageColors, visible}) {

  const [scrollPerc, setScrollPerc] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      window.removeEventListener('scroll', handleScroll);

      if(window.innerWidth <= 768) {
        window.addEventListener('scroll', handleScroll);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  const handleScroll = () => {
    const doc = document.documentElement || document.body
    const scrollPercent = doc.scrollTop / (doc.scrollHeight - doc.clientHeight) * 100
    setScrollPerc(scrollPercent);
  }
  
  return (
    <StyledDiv scrollPerc={scrollPerc} pageColors={pageColors} visible={visible} />
  )
}