import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SidebarNavigation from 'src/components/SidebarNavigation';

const MobileSidebarNavigation = ({
  location,
  sidebarVisible,
  basePath,
  pageColors,
  hideNavigation,
  includeHeaderLinks,
}) => {
  const { designPages } = useStaticQuery(graphql`
    query {
      designPages: allWpDesignPage(sort: { fields: menuOrder, order: ASC }) {
        edges {
          node {
            id
            parentId
            title
            designPagesModules {
              sidebarDescription
              wrapUpActivity
            }
            slug
            path: uri
            parent {
              id
            }
            language {
              slug
            }
          }
        }
      }
    }
  `);

  return (
    <SidebarNavigation
      basePath={basePath}
      includeHeaderLinks={includeHeaderLinks}
      location={location}
      navItems={designPages.edges}
      pageColors={pageColors}
      sidebarVisible={sidebarVisible}
      hideNavigation={hideNavigation}
    />
  );
};

export default MobileSidebarNavigation;
