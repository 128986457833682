import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function supportsIntersectionObserver() {
  return (
    typeof window !== 'undefined' &&
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in IntersectionObserverEntry.prototype
  );
}

export default function IntersectionObserverPolyfill({
  component: Component = 'div',
  className,
  children,
}) {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (supportsIntersectionObserver()) {
      setIsReady(true);
    } else {
      // load the polyfill
      require('intersection-observer');
      setIsReady(true);
    }
  }, []);

  if (typeof window === 'undefined' || isReady) {
    return <Component className={className}>{children}</Component>;
  }

  return null;
}

IntersectionObserverPolyfill.propTypes = {
  component: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
