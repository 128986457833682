import React from 'react';
import styled from '@emotion/styled';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import { wpUrlToGatsbyPath } from 'src/utils/helpers';

const LinkStyles = `
  font-size: inherit;
  text-decoration: none;
`;
const StyledAnchorTag = styled.a`
  ${LinkStyles}
`;
const StyledGatsbyLink = styled(GatsbyLink)`
  ${LinkStyles}
`;
const Link = ({ to, target, rel, children, className, style, ariaLabel }) => {
  const aria = ariaLabel ? { 'aria-label': ariaLabel } : {};
  const href = wpUrlToGatsbyPath(to);
  const isExternal = /(^https?:\/\/|^mailto:|^tel:|\.pdf$)/.test(href);
  const LinkTag = isExternal ? StyledAnchorTag : StyledGatsbyLink;
  const linkProps = isExternal ? { href, target, rel } : { to: href };

  return (
    <LinkTag style={style} className={className} {...linkProps} {...aria}>
      {children}
    </LinkTag>
  );
};

Link.propTypes = {
  to: PropTypes.string,
  target: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  rel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  ariaLabel: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Link.defaultProps = {
  to: '#',
  target: '_blank',
  rel: 'noopener noreferrer',
  ariaLabel: false,
};

export default Link;
